.ui-table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;

    td, th {
        white-space: nowrap;
        overflow: hidden;
        padding: 10px 14px;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
        &:first-child {
            padding-left: 18px;
        }
        &:last-child {
            padding-right: 18px;
        }
        &.aligh-right {
            text-align: right;
        }
    }

    th {
        font-weight: 500;
        border: none;
    }

    thead {
        background-color: var(--tx-table-thead-alt-background);
        color: #6c757e;
        border: none;
    }

    &__cell {
        &--align-right {
            text-align: right;
        }
        &--align-center {
            text-align: center;
        }
        &--align-left {
            text-align: left;
        }
    }
}
