// Styles for mobile version of the transactions table:
.tx-amount{
    &--in{
        color: var(--badge-green-color);
        &::before{
            content: "+ ";
        }
    }
    &--out{
        color: var(--badge-red-color);
        &::before{
            content: "− ";
        }
    }
}

.tx-mobile-icon-container {
    width: 50px;
    padding-right: 4px;
    border: none !important;
}

$mobile-tx-top-border-offset: 1em;

.tx-mobile-flex-content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100% !important;
    line-height: 1.45;
    padding: 0 0 $mobile-tx-top-border-offset 0 !important;
}

.tx-mobile-content {
    flex-grow: 1;
    min-width: 50%;
    padding: $mobile-tx-top-border-offset 14px 0 5px;
    margin-left: 5px;
    border-top: 1px solid var(--card-border-color);
    white-space: nowrap;

    tbody:first-child & {
        border-top: none;
    }

    &__bottom {
        margin-top: 5px;
    }

    &__time {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.tx-mobile-content-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    white-space: nowrap;
    > .address-link {
        max-width: 55%;
    }
    .tx-row-msg-action--single { // TODO
        transform: translateY(50%);
        font-size: unset;
    }
    &__left, &__right {
        display: inline-flex;
        min-width: 0;
    }
    &__left {
        flex-shrink: 1;
        padding-right: 8px;
    }
    &__right {
        flex-shrink: 0;
        text-align: right;
        padding-left: 8px;
    }
}

.tx-mobile-comment {
    display: inline-flex;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    background-color: var(--body-light-muted-color);
    padding: 8px 14px 8px 12px;
    border-radius: 3px 15px 15px 15px;
    white-space: normal;
    position: relative;
    line-height: 1.4;

    &__contents {
        overflow-wrap: anywhere;
        &--truncated {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-align: justify;
            word-break: break-all;
        }
    }

    &__expander {
        line-height: inherit;
        border-bottom-right-radius: inherit;
        padding-bottom: inherit;
        padding-right: inherit;
        padding-left: 15%;
        margin-right: 1em;
        font-size: 1em;
        appearance: none;
        border: none;
        pointer-events: none;
        color: var(--blue-bright);
        background: linear-gradient(to left, var(--tx-table-hide-button-background) 60%, transparent);
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
    }
}

.tx-table-cell-icon {
    // Small old-style square icon:
    &--compact {
        display: inline-flex;
        width: auto;
        height: auto;
        border-radius: 4px;
    }
}

.tx-mobile-icon-container {
    width: 50px;
    padding-right: 4px;
    border: none !important;
    border-right: 8px solid transparent !important;
}

.tx-mobile-left-block {
    text-align: left;
    padding-left: 4px;
    margin-top: 0;

    &__top {
        font-size: 13px;
        display: block;
        margin-bottom: 4px;
    }

    &__bottom {
        display: inline-block;
        max-width: 130px;
    }
}

.tx-mobile-right-block {
    text-align: right;

    &__top {
        margin-bottom: 4px;
    }

    &__bottom {
        font-size: 13px;
    }

    &__top + &__bottom {
        svg {
            width: 16px;
            height: 16px;
        }

        // Counter first cell padding:
        .ui-table td:first-child &,
        .ui-table th:first-child & {
            margin: 0 0 0 -6px;
        }
    }
}

@media screen and (max-width: 600px) {
    .tx-table-cell-icon {
        margin-top: $mobile-tx-top-border-offset!important;
        margin-left: $mobile-tx-top-border-offset!important;
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        display: flex;
        align-items: center;
        align-self: flex-start;
        justify-content: center;
        border-radius: 32px!important;
        svg {
            width: 24px;
            height: 24px;
        }

        &--compact {
            display: inline-flex;
            width: auto;
            height: auto;
            border-radius: 4px;

            svg {
                width: 16px;
                height: 16px;
            }

            // Counter first cell padding:
            .ui-table td:first-child &,
            .ui-table th:first-child & {
                margin: 0 0 0 -6px;
            }
        }
    }

    .tx-table-operation-icon {
        position: relative !important;
        margin-right: 2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        transform: translateY(2.5px);
    }
}
